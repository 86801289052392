<template>
	<div class="container" ref="getheight">
		<div class="flex-row align-center justify-center nav">
			<div class="nav-title-bg"></div>
			<div class="nav-title">订单列表</div>
		</div>
		<div class="list">
			<van-list v-model="loading" @load="pagePointBoxInfoList" :finished="finished" finished-text="没有更多了" :error.sync="error"
				error-text="请求失败，点击重新加载">
				<div class="item flex-column" v-for="(item,index) in list" :key="index">
					<div class="item-header flex-row align-center">
						<img :src="item.materialPic" class="item-img" alt="">
						<div class="item-c">
							<div class="item-name">{{item.boxName}}</div>
							<div class="item-date">抽盒时间：{{item.gainTime}}</div>
						</div>
					</div>
					<div class="item-footer flex-column">
						<div class="flex-row align-center">
							<img src="../../assets/points/icon-wl-no.png" class="icon" alt="">
							<div class="item-con">快递公司：{{item.deliveryStatus == 1?item.expressCompany:'暂无信息'}}</div>
						</div>
						<div class="flex-row align-center mt">
							<img src="../../assets/points/icon-wl-car.png" class="icon" alt="">
							<div class="item-con">物流订单：{{item.deliveryStatus == 1?item.expressNumber:'暂无信息'}}</div>
						</div>
					</div>
				</div>
			</van-list>
		</div>
	</div>
</template>

<script>
	import util from '../../config/util.js';
	import config from '../../config/config.js';
	import wx from 'weixin-js-sdk';
	import {
		Toast,
	} from 'vant';
	import 'vant/lib/toast/index.css';
	import Vue from 'vue';

	export default {
		data() {
			return {
				list: [],
				more: true,
				pageNum: 1,
				pageSize: 10,
				loading: false,
				finished: false,
				error: false
			}
		},
		methods: {
			pagePointBoxInfoList() {
				this.loading = true;
				const that = this;
				this.axios.post(config.pointsRequest +
					`/point-api/rest/pointBoxMaterialUser/pageList?pageNum=${that.pageNum}&pageSize=${that.pageSize}&orderByColumn=gainTime&isAsc=desc`, {}, {
						headers: {
							'Content-Type': 'application/json',
							'token': this.$store.state.user.token
						}
					}).then(response => {
					if (response.data.code == 0) {
						let list = that.list;
						let newList = response.data.data.rows;
						if (newList.length < that.pageSize) that.finished = true;
						for (let i in newList) {
							let gainTime = newList[i].gainTime.replace(/-/g, "/");
							newList[i].gainTime = util.formatDate(gainTime)
						}
						list = list.concat(newList);
						that.list = list;
						that.pageNum = that.pageNum + 1;
						console.log(response)
					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							setTimeout(() => {
								this.$router.push('/')
							}, 1200)
						}
						Toast(response.data.msg);
						this.error = true;
					}
					this.loading = false;
				}, response => {
					this.loading = false;
					this.error = true;
					Toast('获取失败，请稍后重试');
				})
			},

		},
		mounted() {
			util.wxConfig();
		}
	}
</script>

<style scoped="scoped">
	.container {
		width: 100%;
		background: #F4F4F4;
		min-height: 100%;
	}

	.item {
		width: 710px;
		padding: 36px 20px;
		margin: 20px auto 0;
		box-sizing: border-box;
		background: #FFFFFF;
		border-radius: 10px;
	}

	.item-header {
		padding-bottom: 20px;
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	}

	.item-img {
		width: 100px;
		height: 100px;
		margin-right: 20px;
	}

	.item-name {
		font-size: 26px;
		font-weight: 600;
		color: #000000;
	}

	.item-date {
		margin-top: 15px;
		font-size: 26px;
		font-weight: 400;
		color: #6D7278;
	}

	.item-footer {
		margin-top: 30px;
	}

	.icon {
		width: 28px;
		height: 26px;
	}

	.item-con {
		margin-left: 10px;
		font-size: 26px;
		font-weight: 400;
		color: #000000;
	}

	.mt {
		margin-top: 27px;
	}
</style>
